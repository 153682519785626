import jquery from "jquery";
import AOS from "aos";

window.$ = window.jQuery = jquery;

const CALENDLY_URL = "https://calendly.com/jump-partnership-team/schedule-your-call?utm_source=paid_media";

AOS.init({
	disable: "mobile",
});

window.addEventListener("load", AOS.refresh);
let scrollRef = 0;
window.addEventListener("scroll", function () {
	scrollRef <= 10 ? scrollRef++ : AOS.refresh();
});

// TESTIMONIALS SLIDER
if ($(".testimonials-slider").length) {
	var $swiperSelector = $(".swiper-container");

	$swiperSelector.each(function (index) {
		var $this = $(this);
		$this.addClass("swiper-slider-" + index);

		var dragSize = $this.data("drag-size") ? $this.data("drag-size") : 76;
		var freeMode = $this.data("free-mode") ? $this.data("free-mode") : false;
		var loop = $this.data("loop") ? $this.data("loop") : false;
		var slidesDesktop = $this.data("slides-desktop") ? $this.data("slides-desktop") : 1;
		var slidesTablet = $this.data("slides-tablet") ? $this.data("slides-tablet") : 1;
		var slidesMobile = $this.data("slides-mobile") ? $this.data("slides-mobile") : 1;
		var spaceBetween = $this.data("space-between") ? $this.data("space-between") : 1;

		var swiper = new Swiper(".swiper-slider-" + index, {
			direction: "horizontal",
			loop: loop,
			freeMode: freeMode,
			spaceBetween: spaceBetween,
			breakpoints: {
				1920: {
					slidesPerView: slidesDesktop,
				},
				992: {
					slidesPerView: slidesTablet,
				},
				320: {
					slidesPerView: slidesMobile,
				},
			},
			navigation: {
				nextEl: ".swiper-button-next",
				prevEl: ".swiper-button-prev",
			},
			scrollbar: {
				el: ".swiper-scrollbar",
				draggable: true,
				dragSize: dragSize,
			},
		});
	});
}

// MOBILE TABING ON BOX STYLES
if ($(window).width() < 575) {
	$(".tabs-dropdown").each(function (i, elm) {
		$(elm).text($(elm).next("ul").find("li a.active").text());
	});
	$(".tabs-dropdown").on("click", function (e) {
		e.preventDefault();
		$(e.target).toggleClass("open").next("ul").slideToggle();
		$(".digital-marketing-tabs-nav").toggleClass("digital-marketing-tabs-active");
	});
	$('.mobile-tabs a[data-toggle="tab"]').on("click", function (e) {
		e.preventDefault();
		$(e.target).closest("ul").hide().prev("a").removeClass("open").text($(this).text());
		$(".digital-marketing-tabs-nav").removeClass("digital-marketing-tabs-active");
	});
}

function validateFormField(fieldName) {
	const $form = $(".contact-form");
	const $field = $form.find(`[name="${fieldName}"]`);

	let isValid = false;

	if (!$field.val()) {
		$field.addClass("is-invalid");
		$field.next(".field-error-message").fadeIn();
	} else {
		$field.removeClass("is-invalid");
		$field.next(".field-error-message").fadeOut();

		isValid = true;
	}

	return isValid;
}

function validateForm() {
	const $form = $(".contact-form");

	let fieldsWithErrors = [];
	let isFormValid = false;

	if ($(".form-error-wrap").is(":visible")) {
		$(".form-error-wrap").fadeOut();
	}

	$form.find("input, select, textarea").each(function () {
		const $field = $(this);
		const fieldName = $field.attr("name");

		// These fields below don't need validations
		if (
			fieldName === "form-name" ||
			fieldName === "bot-field" ||
			fieldName === "g-recaptcha-response" ||
			fieldName === "pageName" ||
			$field.attr("type") === "checkbox"
		) {
			return;
		}

		const isFieldValid = validateFormField(fieldName);

		if (!isFieldValid) {
			fieldsWithErrors.push(fieldName);
		}
	});

	isFormValid = fieldsWithErrors.length > 0 ? false : true;

	return isFormValid;
}

$(".contact-form").on("submit", function (e) {
	e.preventDefault();

	$(this).find("input, select, textarea, button").attr("disabled", true);
	$(this).find(".multiple-select-label, #mktChallengesPlaceholder").addClass("disabled");
	$(this).find('button[type="submit"] > span').hide();
	$(this).find('button[type="submit"] .loading').show();

	const isFormValid = validateForm();

	if (!isFormValid) {
		$(this).find("input, select, textarea, button").attr("disabled", false);
		$(this).find(".multiple-select-label, #mktChallengesPlaceholder").removeClass("disabled");
		$(this).find('button[type="submit"] .loading').hide();
		$(this).find('button[type="submit"] > span').show();
	} else {
		grecaptcha.execute();
	}
});

function submitData(token) {
	const $form = $(".contact-form");

	const formData = {
		firstName: $form.find('input[name="firstName"]').val(),
		lastName: $form.find('input[name="lastName"]').val(),
		company: $form.find('input[name="company"]').val(),
		email: $form.find('input[name="email"]').val(),
		budget: $form.find('select[name="budget"]').val(),
		marketingChallenges: $form.find('input[name="marketingChallenges"]').val(),
		howDidYouHearAbout: $form.find('select[name="howDidYouHearAbout"]').val(),
		message: $form.find('textarea[name="message"]').val(),
		"g-recaptcha-response": token,
	};

	const monthlyBudget = $form.find('select[name="budget"]').val();
	const shouldRedirect = monthlyBudget !== "$0 - $30,000" ? true : false;

	fetch("/", {
		method: "POST",
		headers: { "Content-Type": "application/x-www-form-urlencoded" },
		body: encodeObjectToURI({
			"form-name": "contact-us",
			...formData,
			pageName: getPageName(),
		}),
	})
		.then((res) => {
			if (!res.ok) {
				throw new Error(`${res.statusText} (${res.status})`);
			}

			// Send formCreativesValid event to GTM
			window.dataLayer.push({
				event: `formCreativesValid`,
			});

			// GTM formDataSent event
			window.dataLayer.push({
				event: `formDataSent`,
			});

			// Send LinkedIn 'track' event
			window.lintrk(`track`, { conversion_id: 15808273 });

			$form.find("input, select, textarea, button").attr("disabled", false);
			$form.find(".multiple-select-label, #mktChallengesPlaceholder").removeClass("disabled");
			$form.find('button[type="submit"] .loading').hide();
			$form.find('button[type="submit"] > span').show();
			$form.trigger("reset");

			$form.hide();
			$(".form-sent-wrap").show().css("display", "flex");

			// Redirects if monthlyBudget !== "$0 - $30,000"
			if (shouldRedirect) {
				window.fbq("track", "399673793948829", "CompleteRegistration");

				window.dataLayer.push({
					event: "formSubmission_30k+",
					formName: "formSubmissionV4",
					budget: formData.budget,
					status: "Completed Registration",
				});

				window.dataLayer.push({
					event: "REDIRECT_CALENDLLY",
				});

				setTimeout(() => {
					window.location.href = CALENDLY_URL;
				}, 2500);
			} else {
				window.dataLayer.push({
					event: "formSubmission_0_30000k",
					formName: "formSubmissionV4",
					status: "Completed Registration",
				});
			}
		})
		.catch((error) => {
			console.error(error);

			$form.find("input, select, textarea, button").attr("disabled", false);
			$form.find(".multiple-select-label, #mktChallengesPlaceholder").removeClass("disabled");
			$form.find('button[type="submit"] .loading').hide();
			$form.find('button[type="submit"] > span').show();

			$(".form-error-wrap").fadeIn();
		});
}

function encodeObjectToURI(data) {
	return Object.keys(data)
		.map((key) => encodeURIComponent(key) + `=` + encodeURIComponent(data[key]))
		.join(`&`);
}

function getPageName() {
	let pageName = "";

	const pageSlug = window.location.pathname;

	if (pageSlug === "/") {
		pageName = "Main Vertical Page";
	} else {
		pageName = pageName
			.split("-")
			.map((word) => {
				if (word.toLocaleLowerCase() === "jump") return;

				const firstLetter = word.charAt(0).toLocaleUpperCase();
				const remainingLetters = word.slice(1);

				return firstLetter + remainingLetters;
			})
			.join(" ");
	}

	return pageName;
}

// Handle custom checkbox change
const marketingChallenges = [];
$(".custom-checkbox input").on("change", function (e) {
	const isChecked = e.target.checked;
	const value = $(this).next("label").find("span").html();

	if (isChecked) {
		marketingChallenges.push(value);
	} else {
		let indexToRemove = marketingChallenges.indexOf(value);
		marketingChallenges.splice(indexToRemove, 1);
	}

	let selectedMktChallenges = "";

	if (marketingChallenges.length > 1) {
		selectedMktChallenges = marketingChallenges.join(", ");
	} else if (marketingChallenges.length === 1) {
		selectedMktChallenges = marketingChallenges.join("");
	}

	if (selectedMktChallenges) {
		$("#mktChallengesPlaceholder").html(selectedMktChallenges);
		$('input[name="marketingChallenges"]').val(selectedMktChallenges);
		$('input[name="marketingChallenges"]').trigger("change");

		$("#mktChallengesPlaceholder").removeClass("is-invalid");
		$(".multiple-select-error").fadeOut();
	} else {
		$("#mktChallengesPlaceholder").html("Choose multiple options");
		$('input[name="marketingChallenges"]').val("");
		$('input[name="marketingChallenges"]').trigger("change");
	}
});

// Handle clicking outside of the box when 'multiple-select-content' box is opened
$("body").on("click", function (e) {
	if (
		!$(e.target).is(
			".multiple-select-content, .multiple-select-content *, .multiple-select-label, #mktChallengesPlaceholder"
		) &&
		$(".multiple-select-content").hasClass("show")
	) {
		$(".multiple-select-content").removeClass("show");
	}
});

// Hide/show multiple-select-content
$(".multiple-select-label, #mktChallengesPlaceholder").on("click", function () {
	if (!$(this).hasClass("disabled")) {
		$(".multiple-select-content").toggleClass("show");
	}
});

// Initializes Google reCaptcha manually
$(window).on("load", () => {
	grecaptcha.render("recaptcha", {
		sitekey: "6LeFKSAqAAAAAPHBDgu6dbxrqkHLcVQnnh_UOjrU",
		callback: submitData,
		size: "invisible",
	});
});

// Re-validates all field forms onChange
$(".contact-form")
	.find('input:not([type="checkbox"]), select, textarea')
	.on("change", function () {
		validateFormField($(this).attr("name"));
	});
